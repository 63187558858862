import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useOpenShiftCount } from "@src/appV2/OpenShifts/api/useOpenShiftCount";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addMonths } from "date-fns";
import { type ReactElement, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  getDescriptionForRequirementType,
  getPotentialShiftsAndFacilitiesForRequirements,
} from "../../utils";
import { AccountsRequirementAccordion } from "../Components";
import {
  CHECKR_REQUIREMENT_ID,
  DEFAULT_DISTANCE_IN_MILES,
  REQUIREMENT_STATUS_DATA,
} from "../constants";
import { DocumentRequirementType, type HcpRequirement } from "../types";
import { CheckrAccountsRequirement } from "./CheckrRequirement";
import { AccountsRequirement } from "./Requirement";

interface AccountsRequirementsProps {
  requirementType: DocumentRequirementType;
  hcpRequirementIds: string[];
  isExpanded: boolean;
  requirements: Record<string, HcpRequirement>;
  documentsDropOffFlowId: string;
  qualification?: string;
  allRequirementsMissingAtHcfLevel?: HcpRequirement[];
}

export function AccountsRequirements(props: AccountsRequirementsProps): ReactElement {
  const {
    requirementType,
    hcpRequirementIds,
    isExpanded,
    requirements,
    documentsDropOffFlowId,
    qualification,
    allRequirementsMissingAtHcfLevel,
  } = props;
  const { title, isCollapsible } = REQUIREMENT_STATUS_DATA[requirementType];
  const params = useParams<{ hcfId?: string; hcfName?: string }>();

  const [expandedAccordion, setExpandedAccordion] = useState<string[]>(
    isExpanded ? [requirementType] : []
  );

  const filteredHcpRequirementIds = hcpRequirementIds.filter((requirementId) =>
    isDefined(requirements[requirementId])
  );
  const worker = useDefinedWorker();

  useLogEffect(
    APP_V2_APP_EVENTS.REQUIREMENT_TYPE_DOCUMENTS_COUNT_EVENT,
    {
      requirementType,
      requirementCount: filteredHcpRequirementIds.length,
      workerUserId: worker?._id,
    },
    { enabled: isDefined(worker) }
  );

  const commonOpenShiftCountProps = {
    qualification,
    coordinates: worker?.geoLocation?.coordinates,
    specialities: {
      hasSedationExperience: worker?.specialities?.hasSedationExperience ?? false,
      hasTrayAssemblyExperience: worker?.specialities?.hasTrayAssemblyExperience ?? false,
    },
    distance: DEFAULT_DISTANCE_IN_MILES,
    tmz: worker?.tmz ?? "",
    isAgent: true,
  };

  const isOpenShiftsCountEnabled =
    isDefined(worker) && requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES;

  const {
    data: openShiftCountDataForFirstMonth,
    isSuccess: isOpenShiftsCountSuccessfulForFirstMonth,
  } = useOpenShiftCount(
    {
      startDate: new Date(),
      endDate: addMonths(new Date(), 1),
      ...commonOpenShiftCountProps,
    },
    {
      enabled: isOpenShiftsCountEnabled,
    }
  );

  const {
    data: openShiftCountDataForSecondMonth,
    isSuccess: isOpenShiftsCountSuccessfulForSecondMonth,
  } = useOpenShiftCount(
    {
      startDate: addMonths(new Date(), 1),
      endDate: addMonths(new Date(), 2),
      ...commonOpenShiftCountProps,
    },
    {
      enabled: isOpenShiftsCountEnabled,
    }
  );

  /* We use two separate API calls and combine the results as there is a heavy server load
   on the open shift count API for asking for 2 months at once. */
  const isOpenShiftsCountSuccessful =
    isOpenShiftsCountSuccessfulForFirstMonth && isOpenShiftsCountSuccessfulForSecondMonth;

  const openShiftCountData = isOpenShiftsCountSuccessful
    ? {
        ...openShiftCountDataForFirstMonth,
        ...openShiftCountDataForSecondMonth,
      }
    : undefined;

  const requirementToPotentialShiftsAndFacilitiesMapping =
    isDefined(openShiftCountData) && isDefined(allRequirementsMissingAtHcfLevel)
      ? getPotentialShiftsAndFacilitiesForRequirements({
          openShiftCountData,
          allRequirementsMissingAtHcfLevel,
          qualification: qualification ?? worker.qualification,
        })
      : undefined;

  const sortedRequirements = useMemo(() => {
    if (
      requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES &&
      isDefined(requirementToPotentialShiftsAndFacilitiesMapping)
    ) {
      return filteredHcpRequirementIds
        .map((requirementId) => {
          const mappingCounts = requirementToPotentialShiftsAndFacilitiesMapping.get(requirementId);
          return {
            requirementId,
            potentialShiftsCount: mappingCounts?.potentialShiftsCount,
            potentialFacilitiesCount: mappingCounts?.potentialFacilitiesCount,
          };
        })
        .sort((a, b) => {
          if (b.potentialShiftsCount !== a.potentialShiftsCount) {
            return (b.potentialShiftsCount ?? 0) - (a.potentialShiftsCount ?? 0);
          }

          return (b.potentialFacilitiesCount ?? 0) - (a.potentialFacilitiesCount ?? 0);
        });
    }

    return filteredHcpRequirementIds.map((requirementId) => ({
      requirementId,
      potentialShiftsCount: undefined,
      potentialFacilitiesCount: undefined,
    }));
  }, [
    requirementType,
    requirementToPotentialShiftsAndFacilitiesMapping,
    filteredHcpRequirementIds,
  ]);

  return (
    <AccountsRequirementAccordion
      isCollapsible={isCollapsible}
      requirementCount={hcpRequirementIds.length}
      groupTitle={
        isDefined(params?.hcfName) &&
        isDefined(params?.hcfId) &&
        requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES
          ? `Required by this facility`
          : title
      }
      isExpanded={expandedAccordion.includes(requirementType)}
      description={getDescriptionForRequirementType(requirementType, sortedRequirements.length)}
      onAccordionExpanded={() => {
        if (expandedAccordion.includes(requirementType)) {
          setExpandedAccordion(
            expandedAccordion.filter((accordion) => accordion !== requirementType)
          );
        } else {
          setExpandedAccordion([...expandedAccordion, requirementType]);
        }
      }}
    >
      <Stack spacing={2}>
        {sortedRequirements.map(
          ({ requirementId, potentialShiftsCount, potentialFacilitiesCount }) => {
            const requirement = requirements[requirementId];
            if (requirementId === CHECKR_REQUIREMENT_ID) {
              return (
                <CheckrAccountsRequirement
                  key={requirementId}
                  requirement={requirement}
                  requirementType={requirementType}
                />
              );
            }

            return (
              <AccountsRequirement
                key={requirementId}
                requirement={requirement}
                requirementType={requirementType}
                qualification={qualification}
                documentsDropOffFlowId={documentsDropOffFlowId}
                {...(isDefined(potentialShiftsCount) && isDefined(potentialFacilitiesCount)
                  ? {
                      potentialShiftsAndFacilitiesCounts: {
                        potentialShiftsCount,
                        potentialFacilitiesCount,
                      },
                    }
                  : {})}
              />
            );
          }
        )}
      </Stack>
    </AccountsRequirementAccordion>
  );
}
